@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/extend';

.bp__dialog {
  .e_dialog {
    background-color: $dark-overlay;
    font-family: $fonts-seq;
    font-stretch: normal;
    font-style: normal;
    inset: 0 0 0 0;
    letter-spacing: normal;
    line-height: normal;
    position: fixed;
    z-index: 110;

    &__wrapper {
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      width: calc(100% - 60px);

      @extend %hr-vr-center;
    }

    &__header {
      align-items: center;
      background-color: $light-bg1;
      border-radius: 10px 10px 0 0;
      box-shadow: 0 2px 10px 0 rgb(63 67 71 / 8%);
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;

      &__title {
        color: $light-color2;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }

      &--close-button {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        outline: none;
        padding: 0;

        .bp__icon {
          height: 14px;
          width: 14px;
        }
      }
    }

    &__body {
      background-color: $light-bg1;
      overflow: hidden;
      overflow-y: auto;

      &__wrapper {
        padding: 20px 20px 35px;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #fafafa;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #c3c3c3;
        border-radius: 3px;
      }
    }

    &__content {
      color: $light-color2;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    &__footer {
      background-color: $light-bg1;
      border-radius: 0 0 10px 10px;
      border-top: 1px solid $light-border;
      padding: 20px;

      &__wrapper {
        text-align: center;
      }

      :has(.bp__ab--locked) {
        justify-content: center;
      }

      button {
        &:not(:first-child) {
          margin-left: 20px;
        }

        &.bp__ab--disabled {
          &:disabled {
            background-color: $light-color1;
          }
        }

        &.bp__ab--locked {
          background-color: unset;
          border: unset;
          color: #01b5de !important;
          font-weight: 500;
          height: unset !important;
        }
      }
    }

    &--mobile-view {
      .e_dialog {
        &__header {
          border-bottom: 1px solid $light-border;
        }

        &__footer {
          padding: 0;

          &__wrapper {
            display: flex;
          }

          button {
            font-size: 16px;
            height: 52px;
            margin: 10px;

            &:not(:last-child) {
              border-right: 1px solid $light-border;
            }
          }

          .bp__ab--mobile-view {
            &:last-child {
              @extend %color-blue;
            }
          }
        }
      }
    }

    &--top-view {
      display: flex;
      padding: 0;

      .e_dialog {
        &__wrapper {
          align-self: flex-start;
          animation: kf-slide-up ease-in-out 0.5s;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
          border-radius: 0;
          left: 0;
          right: 0;
          top: 0;
          transform: none;
          width: 100%;
        }

        &__header {
          border-bottom: 1px solid $light-border;
          border-radius: 0;
        }
      }
    }

    &--bottom-view {
      display: flex;
      padding: 0;

      .e_dialog {
        &__wrapper {
          align-self: flex-end;
          border-radius: 0;
          inset: auto 0 0 0;

          // animation: kf-slide-up ease-in-out 0.5s;
          // animation-fill-mode: forwards;
          // animation-iteration-count: 1;
          position: absolute;
          transform: none;
          width: 100%;
        }

        &__header {
          border-bottom: 1px solid $light-border;
          border-radius: 0;
        }
      }
    }

    &--button-w100 {
      .e_dialog {
        &__footer {
          &__wrapper {
            display: block;
          }

          button {
            height: 45px;
          }
        }
      }
    }
  }

  .bp__discard__change-popup {
    .e_dialog {
      &__wrapper {
        border-radius: 8px;
        width: 300px;
      }

      &__header {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        padding: 16px;

        &__title {
          font-family: $fonts-seq;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      &__body {
        align-items: flex-start;
        border: unset;
        display: flex;
        font-family: $fonts-seq;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding: 24px 16px;
        text-align: left;
      }

      &__footer {
        border: none;
        padding: 0 16px 16px 16px;

        &__wrapper {
          display: flex;
          flex-direction: row;
          gap: 16px;
          justify-content: space-evenly;
        }

        button {
          align-items: center;
          border: 1px solid $color-blue !important;
          border-radius: 8px !important;
          color: $color-blue !important;
          display: flex;
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          justify-content: center;
          line-height: 24px;
          padding: 12px 16px;
        }

        button:nth-child(2) {
          background-color: $color-blue !important;
          color: $light-color1 !important;
        }
      }
    }
  }

  .slide-up {
    .e_dialog {
      &__wrapper {
        animation: kf-slide-up ease-in-out 0.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
    }
  }

  .slide-down {
    .e_dialog {
      &__wrapper {
        animation: kf-slide-down ease-in-out 0.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  .bp__dialog {
    .e_dialog {
      &__wrapper {
        border-radius: 8px;
      }

      &__header {
        border-radius: 8px 8px 0 0;
      }

      &__content {
        font-size: 16px;
        line-height: 28px;
      }

      &--mobile-view {
        .bp__ab--disabled {
          &:disabled {
            background-color: $button-bg-diabled !important;
            color: $light-color1 !important;
          }
        }

        .bp__ab--mobile-view {
          &:last-child {
            background-color: $color-blue;
            border-radius: 30px !important;
            color: $light-color1 !important;
          }
        }

        .e_dialog__footer {
          button {
            &:not(:last-child) {
              border-right: none;
            }
          }
        }
      }

      &__footer {
        border-radius: 0 0 8px 8px;
        border-top: none;

        &__wrapper {
          align-items: center;
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
        }

        button {
          &.bp__ab--locked {
            font-size: 20px;
          }
        }
      }
    }

    .sg__change-popup, .sa__change-popup {
      .e_dialog__wrapper {
        max-width: 712px;

        .e_dialog__footer {
          padding: 20px 40px;

          &__wrapper {
            flex-direction: row;
            gap: 10px;

            button {
              padding: 10px;
            }

            button:nth-child(1) {
              border: 1px solid $color-blue;
              border-radius: 30px !important;
              color: $color-blue;
            }

            button:nth-child(2) {
              background-color: $color-blue !important;
            }
          }
        }
      }
    }

    .bp__discard__change-popup {
      .e_dialog__wrapper {
        max-width: 712px;

        .e_dialog__body {
          font-size: 12px;
        }

        .e_dialog__footer {
          border: none;
          padding: 0 16px 16px 16px;

          &__wrapper {
            display: flex;
            flex-direction: row;
            gap: 16px;
            justify-content: space-evenly;
          }

          button {
            align-items: center;
            border: 1px solid $color-blue !important;
            border-radius: 8px !important;
            color: $color-blue !important;
            display: flex;
            flex: 1;
            justify-content: center;
            padding: 12px 16px;
          }

          button:nth-child(2) {
            background: $color-blue;
            color: $light-color1 !important;
          }
        }
      }
    }

    .sp__tou-alert-popup,
    .sp__survey-alert-popup {
      .e_dialog__wrapper {
        .e_dialog__footer__wrapper {
          button:nth-child(1) {
            border-top: 1px solid rgb(123 135 147 / 30%);
          }
        }
      }
    }

    .bp__alert-popup {
      .bp__popup__wrapper {
        border-bottom: 1px solid rgb(123 135 147 / 30%);
      }
    }
  }

  .e_dialog {
    &__footer {
      border-radius: 0 0 8px 8px;
    }

    &--mobile-view {
      .e_dialog {
        &__footer {
          .bp__ab--mobile-view {
            &:last-child {
              color: $light-color1;
            }
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  &.bp__dialog {
    .e_dialog {
      background-color: $dark-overlay;

      &__header {
        background-color: $dark-bg2;
        border-color: $dark-border;

        &__title {
          color: $dark-color1;
        }
      }

      &__body {
        background-color: $dark-bg2;

        hr {
          border-color: $dark-border;
        }

        &::-webkit-scrollbar-track {
          background-color: $dark-bg2;
        }

        &::-webkit-scrollbar-thumb {
          background-color: $dark-bg1;
        }
      }

      &__content {
        color: $dark-color1;
      }

      &__footer {
        border-color: $dark-border;

        &__wrapper {
          background-color: $dark-bg2;

          button {
            background-color: $dark-bg2 !important;
          }
        }
      }

      &--mobile-view {
        .e_dialog {
          &__footer {
            background-color: $dark-bg2;

            button {
              &:not(:last-child) {
                border-color: $dark-border;
              }
            }
          }
        }
      }
    }

    &.e_dialog--bottom-view {
      .e_dialog {
        &__content {
          background-color: $dark-bg1;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Orange Theme
 * *********************** *********************** *********************** */

.orange-theme {
  .bp__dialog {
    .e_dialog {
      &--mobile-view {
        .e_dialog {
          &__footer {
            .bp__ab--mobile-view {
              &:last-child {
                @extend %color-orange;
              }
            }
          }
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Media Queries
 * *********************** *********************** *********************** */

@media (width <=359px) {
  .bp__dialog {
    .e_dialog {
      &__wrapper {
        width: calc(100% - 40px);
      }
    }
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (width >= 576px) {
  /* Small devices */
}

/* Medium devices (tablets, 768px and up) */
@media (width >= 768px) {
  .bp__dialog {
    .e_dialog {
      &__wrapper {
        width: 450px;
      }
    }

    &.e_dialog--bottom-view {
      display: block;

      .e_dialog {
        &__wrapper {
          animation: none;
          border-radius: 0;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 500px;
        }

        &__header {
          &__title {
            font-size: 18px;
          }
        }
      }
    }
  }
}

/* Large devices (desktops, 992px and up) */
@media (width >= 992px) {
  .bp__dialog {
    .e_dialog {
      &__wrapper {
        width: 500px;
      }

      &__header {
        &__title {
          font-size: 20px;
        }
      }

      &__footer {
        padding: 30px;

        button:not(:first-child) {
          margin-left: 30px;
        }
      }
    }
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (width >= 1200px) {
  /* Extra large devices */
}
