@import '../../../../assets/styles/variable';

.bp__system-profile {
  &--card {
    display: flex;
    flex-flow: column;
    height: inherit;
    justify-content: space-between;

    .sp {
      &__profile {
        cursor: pointer;

        &:not(:first-child) {
          border-top: 1px solid $light-border;
          margin-top: 16px;
          padding-top: 16px;
        }

        .bp__action-button {
          border-radius: 8px;
          font-size: 10px;
          height: 25px;
          margin: 6px 0 0;
          padding: 2px 10px 0;
          text-transform: uppercase;
        }

        &--title {
          &.atom-design--t3 {
            gap: 0 8px;
            grid-template-columns: 20px auto 20px;
            margin: 0;

            .com__ad__icon {
              input[type='radio'] {
                display: block;
              }
            }

            .com__ad__details {
              .com__ad--title {
                font-size: 16px;
                font-weight: 500;
                margin: 3px 0 0;

                &-prefix {
                  background-color: $color-error;
                  border-radius: 14.5px;
                  color: $light-color1;
                  font-size: 10px;
                  font-weight: 400;
                  line-height: 16px;
                  margin-left: 4px;
                  margin-top: -15px;
                  padding: 0 6px;
                }

                &-prefix-space {
                  margin-left: 4px;
                  margin-right: 4px;
                }

                .com__ad--info {
                  font-weight: 400;
                }
              }
            }

            .com__ad__action {
              .bp__icon {
                height: 20px;
              }
            }
          }
        }

        .evs {
          &--ev-chargers {
            .evs--ev-charger {
              &:not(:first-child) {
                margin-top: 6px;
              }
            }
          }
        }
      }

      &__desc {
        color: $light-color3;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        margin-top: 6px;
      }

      &--use-battery {
        // TODO
      }

      &--reserve {
        .atom-design--t3 {
          gap: 0;

          .com__ad {
            &--title {
              font-weight: 500;
              margin-top: 3px;
            }

            &__action {
              color: $light-color2;
              margin-top: 3px;
            }
          }

          .bp__icon {
            svg {
              margin-left: -3px;
            }
          }
        }
      }
    }

    .evs {
      &--ev-charger {
        &.atom-design--t3 {
          gap: 0 8px;

          .com__ad--title {
            color: $light-color6;
          }

          .com__ad {
            &__details {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              position: relative;

              .db__value {
                bottom: -15px;
                position: absolute;
                width: max-content;
              }
            }

            &__charger-name {
              display: flex;
            }

            &__title {
              align-items: center;
              color: $light-color2;
              display: flex;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
            }

            &--info {
              margin-left: 5px;
            }

            &__action {
              align-self: flex-start;
            }
          }
        }
      }
    }

    .bp__card-view {
      margin: 0;
    }

    .bp__page {
      &--actions {
        display: flex;
        justify-content: space-between;

        .bp__action-button {
          border-radius: 8px;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 16px 0 0;

          &.ib__btn--loading {
            background-color: transparent !important;
            border-color: $color-blue;
          }
        }
      }
    }

    .bp__action-button.bp__ab--round-corner {
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
    }
    
    .bp__action-button.bp__ab--round-corner.bp__ab--outline.bp__ab--full-width.ib__btn--cancel{
      margin-right: 16px;
    }
  }

  &--tutorial {
    .sp__profile {
      &--title {
        &.atom-design--t3 {
          grid-template-columns: auto 20px;
        }
      }
    }
  }
}

.bp {
  &__discard__change-popup {
    .e_dialog__body {
      color: #545456;
      font-size: 12px;
      padding: 30px 20px;
      text-align: center;
    }
  }

  &__frp-show-up {
    .e_dialog__wrapper {
      .e_dialog__body {
        padding: 20px;
      }
    }
  }

  &__none__profile-popup {
    .e_dialog__body__wrapper {
      padding-top: 40px;

      .e_dialog__content {
        div {
          font-size: 13px;
          line-height: 18px;
          margin: 30px 0 0;
          text-align: center;
        }

        .confirm__msg {
          margin: 16px 0 0;
        }

        .info__msg {
          font-size: 11px;
        }
      }
    }

    .bp__icon {
      margin-top: 20px;

      &.icon-info-v2 {
        svg {
          margin: auto;
        }
      }
    }
  }
}

.sp{
  &__tou-alert-popup, &__survey-alert-popup{
    .e_dialog__body__wrapper{
      padding-top: 40px;
    }

    .bp__icon {
      margin-top: 20px;

      &.icon-alert {
        height: 50px;
        width: 50px;

        svg {
          margin: auto;
        }
      }

      &.icon-info-v2 {
        height: 50px;
        width: 50px;

        svg {
          margin: auto;
        }
      }
    }

    .e_dialog__content {
      font-size: 14px;
      line-height: 18px;
      margin: 16px 0 0;
      text-align: center;

      &:first-of-type {
        margin-top: 25px;
      }
    }
  }

  &__survey-alert-popup {
    .e_dialog__body {
      .e_dialog__body__wrapper {
        .bp__icon{
          &.icon-alert{
            height: 56px;
            width: 56px;
          }
        }
        .e_dialog_title {
          text-align: center;
          color:#545456;
          margin-top: 24px;
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .e_dialog__footer__wrapper {
      display: block !important;
    }
  }
}

/* *********************** *********************** *********************** *
 * Dark Mode
 * *********************** *********************** *********************** */

.dark-theme {
  .bp__system-profile--card,
  .bp__card--system-profile {
    .sp__profile {
      .sp--reserve {
        .com__ad__action {
          color: $light-color1;
        }
      }
    }

    .sp__profile:not(:first-child) {
      border-top: 1px solid $light-color6;
    }
  }

  .sp {
    &__survey-alert-popup{
      .e_dialog__body {
        .e_dialog__body__wrapper {
          .icon-alert {
            svg {
              path {
               stroke: #FFFFFF;
               &#Path, &#Path-2 {
                fill: #FFFFFF;
               }
              }
            }
          }
          .e_dialog_title {
            color:#ffff;
          }
        }
      }
    }
  }

  .bp {
    &__discard__change-popup {
      .e_dialog__body {
        color: #ffff;
      }
    }
  }

  .evs {
    &--ev-charger {
      &.atom-design--t3 {
        .bp__icon {
          svg g path {
            fill: $dark-color1;
            stroke: $dark-color1;
          }
        }

        .com__ad__title {
          color: $dark-color1;
        }
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Web View
 * *********************** *********************** *********************** */

.web-view {
  .bp__system-profile {
    &--card {
      justify-content: flex-start;

      .sp {
        &__profile {
          .bp__action-button {
            margin-top: 40px;
          }

          &--title {
            &.atom-design--t3 {
              gap: 0 16px;

              .com__ad--title > span {
                text-wrap: nowrap;
              }
            }
          }
        }

        &--reserve {
          .atom-design--t3 {
            .com__ad {
              &--title {
                font-size: 15px;
              }

              &__action {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .bp {
    &__discard__change-popup {
      .e_dialog__body {
        font-size: 15px;
        text-align: left;
      }
    }
  }
}

/* *********************** *********************** *********************** *
 * Media Queries
 * *********************** *********************** *********************** */

@media (width >= 768px) {
  .web-view {
    .bp__dialog {
      .sp {
        &__survey-alert-popup {
          .e_dialog__wrapper {
            width: 672px;
            .e_dialog_title {
              font-size: 20px;
            }
            .e_dialog__content {
              padding: 0px 20px;
            }
            .e_dialog__footer {
              button {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
